import { Slot } from '@radix-ui/react-slot';
type HardLinkProps = React.ComponentProps<typeof Slot> & {
  href: string;
};

function HardLink({ href, ...props }: HardLinkProps) {
  const onClick = () => {
    if (window.history) {
      history.pushState({}, window.location.href);
    }
    window.location.replace(href);
  };
  return <Slot {...props} onClick={onClick} />;
}

export default HardLink;

import { type ButtonProps, Button } from '@ion/design-system';
import * as Sentry from '@sentry/nextjs';
import { SentryError } from '@sentry/utils';
import { useEffect } from 'react';

import CenteredCard from './centered-card';
import { Icons } from './icons';
import Layout from './layout';

type ErrorPageProps = {
  code: number | string;
  message?: string;
  buttonProps?: ButtonProps;
  path?: string;
  windowOpen?: boolean;
};

const ErrorPage = ({ code, message, buttonProps, path, windowOpen = false }: ErrorPageProps) => {
  const onClick = () => {
    if (window.history) {
      history.pushState({}, window.location.href);
    }
    window.location.replace(path ?? '/');
  };
  useEffect(() => {
    Sentry.captureException(new SentryError(`Error ${code}: ${message}`));
  }, []);
  const defaultContent = (
    <div>
      Something went wrong on our end, refresh the page or try again. If the problem persists, please reach out to
      team@ion.design or ping us in your slack channel.
    </div>
  );
  return (
    <Layout>
      <CenteredCard>
        <h1 className="mb-2 flex items-center space-x-2 text-2xl font-bold">
          <Icons.error className="text-destructive mr-4" />
          {code}
        </h1>
        <p className="text-secondary">{message ?? defaultContent}</p>

        <Button iconLeading={<Icons.home className="w-4 h-4" />} className="mt-4" {...buttonProps} onClick={onClick}>
          {buttonProps?.children ?? 'Back home'}
        </Button>
      </CenteredCard>
    </Layout>
  );
};

export default ErrorPage;

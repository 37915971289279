import Layout from '@/components/layout';
import Projects from '@/components/screens/projects';

function ProjectsPage() {
  return (
    <Layout auth>
      <Projects />
    </Layout>
  );
}
export default ProjectsPage;

import { Button, Popover, PopoverContent, PopoverTrigger } from '@ion/design-system';
import React from 'react';
import { match } from 'ts-pattern';

import { Icons } from '../icons';

interface Props {
  onConfirm: () => void;
  variant?: 'info' | 'destructive';
  description?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode | React.ReactNode[];
}

export function PopConfirm({
  variant = 'info',
  children,
  onConfirm,
  description,
  open: userOpen,
  onOpenChange: userOnOpenChange,
}: Props) {
  const [defaultOpen, defaultOnOpenChange] = React.useState(false);
  const { open, setOpen } = {
    open: userOpen ?? defaultOpen,
    setOpen: userOnOpenChange ?? defaultOnOpenChange,
  };
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="flex flex-col gap-2 z-50 min-w-fit">
        <h2 className="text-foreground mb-1 flex items-center text-sm font-medium shrink-0">
          {match(variant)
            .with('info', () => <Icons.info className="mr-2 h-4 w-4" />)
            .with('destructive', () => <Icons.delete className="text-danger mr-2 h-4 w-4" />)
            .exhaustive()}
          Are you sure?
        </h2>
        {description && <p className="text-secondary mb-5 text-xs font-normal">{description}</p>}
        <div className="flex items-center space-x-2 flex-1">
          <Button
            size={'sm'}
            onClick={() => {
              onConfirm();
              setOpen(false);
            }}
            color="danger"
            className="w-full"
          >
            Delete
          </Button>
          <Button size={'sm'} variant={'outline'} onClick={() => setOpen(false)} className="w-full">
            Cancel
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}

import { api } from '@ion/api';
import { PosthogEvents, trackEvent } from '@ion/api/posthog';
import { Button, Card, toast } from '@ion/design-system';
import { useSession } from '@ion/next-auth/react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { Icons } from '@/components/icons';
import Spinner from '@/components/ui/spinner';

import ErrorPage from '../error-page';
import HardLink from '../hard-link';
import { NewProjectModal } from '../modals/project/new-project-modal';
import { ToggleTheme } from '../toggle-theme';
import { PopConfirm } from '../ui/pop-confirm';

dayjs.extend(relativeTime);

function Projects() {
  const { data: userData } = useSession();

  const { data, error, isLoading, refetch } = api.generations.getUserProjects.useQuery();
  const deleteProjectById = api.generations.deleteProjectById.useMutation({
    onSuccess: () => {
      void refetch();
    },
    onError: () => {
      toast.error('Failed to delete project');
    },
  });
  const [deletedIds, setDeletedIds] = React.useState<string[]>([]);

  useEffect(() => {
    if (userData) {
      trackEvent(PosthogEvents.PROJECTS_PAGE_OPENED, userData.user);
    }
  }, [userData]);

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Spinner />
      </div>
    );

  if (error) {
    return <ErrorPage code={404} message="Something went wrong" />;
  }

  if (!data) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-t from-background to-primary-container w-full h-full mx-auto flex flex-col items-center gap-8  p-12 dark:from-neutral-accent dark:to-neutral-container justify-center">
      <Card
        title="Projects"
        subtitle={data.projects.length === 0 && 'Get started by creating a new project'}
        contentClassName="w-full"
        className={clsx('w-full relative', {
          'max-w-screen-lg': data.projects.length !== 0,
          'max-w-screen-sm': data.projects.length === 0,
        })}
      >
        <div className="absolute right-5 top-5 flex flex-row gap-2">
          <Link href="/settings">
            <Button variant="ghost" iconLeading={<Icons.gear className="h-4 w-4" />} />
          </Link>
          <ToggleTheme />
        </div>
        <div className="flex flex-col w-full gap-4 w-full">
          {data.projects.length === 0 && (
            <div className="h-48 flex items-center justify-center flex-col w-full">
              <NewProjectModal
                trigger={
                  <Button iconLeading={<Icons.add className="h-4 w-4" />} size="lg">
                    Create New Project
                  </Button>
                }
              />
            </div>
          )}
          <div className="grid sm:grid-cols-1  md:grid-cols-2 xl:grid-cols-3 gap-2 max-h-[600px] overflow-y-auto">
            {data.projects.map((project) => (
              <Card className="w-full" contentClassName="flex-1 h-full">
                <div className="w-full h-36 flex items-center justify-center text-subtle rounded-radius border border-stroke-subtle p-1">
                  {project.previewUrl ? (
                    <img
                      src={project.previewUrl}
                      alt={`${project.name} preview image`}
                      className="w-full h-full object-contain"
                    />
                  ) : (
                    <span className="text-xs flex gap-2">
                      <Icons.image className="h-4 w-4" /> No preview image
                    </span>
                  )}
                </div>
                <HardLink
                  href={`/project/${project.id}`}
                  className="active:text-neutral-pressed hover:text-neutral-hover cursor-pointer"
                >
                  <h2 className="font-semibold mt-2">{project.name}</h2>
                </HardLink>
                <div className="font-mono text-sm text-secondary">
                  {project.versionHistory.length + 1} {project.versionHistory.length === 0 ? 'iteration' : 'iterations'}
                </div>
                <div className="text-sm text-subtle">{dayjs(project.createdAt).fromNow()}</div>
                <span className="flex gap-2 items-center justify-end mt-auto pt-4">
                  <PopConfirm
                    variant="destructive"
                    onConfirm={() => {
                      setDeletedIds((prev) => [...prev, project.id]);
                      void deleteProjectById.mutate(project.id);
                      if (userData) {
                        trackEvent(PosthogEvents.DELETED_PROJECT, userData.user, { projectId: project.id });
                      }
                    }}
                  >
                    <Button
                      iconLeading={
                        deleteProjectById.isLoading && deletedIds.includes(project.id) ? (
                          <Spinner size="sm" />
                        ) : (
                          <Icons.delete className="h-4 w-4" />
                        )
                      }
                      variant={'outline'}
                      color={'danger'}
                      className="w-full"
                      // isLoading={deleteFigmaFileLoading && deletedIds.includes(figmaFile.fileKey)}
                    >
                      Delete
                    </Button>
                  </PopConfirm>
                  <HardLink key={project.id} href={`/project/${project.id}`}>
                    <Button
                      iconLeading={<Icons.internalLink className="h-4 w-4 shrink-0" />}
                      variant="soft"
                      className="w-full"
                    >
                      Open
                    </Button>
                  </HardLink>
                </span>
              </Card>
            ))}
          </div>
        </div>
        {data.projects.length !== 0 && (
          <NewProjectModal
            trigger={
              <Button
                className="mt-4 ml-auto"
                iconLeading={<Icons.add className="h-4 w-4" />}
                onClick={() => {
                  if (userData) {
                    trackEvent(PosthogEvents.CREATED_PROJECT_MODAL_OPENED, userData.user);
                  }
                }}
              >
                Create New Project
              </Button>
            }
          />
        )}
      </Card>
    </div>
  );
}
export default Projects;
